import React from 'react'
import './how.sass'
import { StaticImage } from 'gatsby-plugin-image'
import { useIntl } from 'gatsby-plugin-intl'

const How = () => {
  const intl = useIntl()
  return (
  <div className="howSection">
    <div className="howRow">
      <div className="howTextBlocksContainer">
        <h1>{intl.formatMessage({ id: 'how-to-buy' })}</h1>
        <div className="howText">
          <div className='howLine'></div>
            <div>
              <span className="howNumber">1</span>
              <span className="howTitle">{intl.formatMessage({ id: 'how-title1' })}</span>
              <p>{intl.formatMessage({ id: 'how-to-buy1' })}</p>
            </div>
            <div>
              <span className="howNumber">2</span>
              <span className="howTitle">{intl.formatMessage({ id: 'how-title2' })}</span>
              <p>{intl.formatMessage({ id: 'how-to-buy2' })}</p>
            </div>
            <div>
              <span className="howNumber">3</span>
              <span className="howTitle">{intl.formatMessage({ id: 'how-title3' })}</span>
              <p>{intl.formatMessage({ id: 'how-to-buy3' })}</p>
            </div>
        </div>
      </div>
          <StaticImage
            src="../../images/token-1.png"
            height={80}
            style={{ top: 150, left: 25, transform: 'scaleX(-1)' }}
            className="howBoo mobile one"
            placeholder="memeraidsFish"
            layout="fixed"
            alt="LuckyBoo logo"
          />
           <StaticImage
            src="../../images/token-1.png"
            height={150}
            style={{ top: 100, left: 220, transform: 'scaleX(-1)' }}
            className="howBoo"
            placeholder="memeraidsFish"
            layout="fixed"
            alt="LuckyBoo logo"
          />
           <StaticImage
            src="../../images/token-1.png"
            height={75}
            style={{ top: 200, left: 500, transform: 'scaleX(-1) rotate(40deg)' }}
            className="howBoo mobile two"
            placeholder="memeraidsFish"
            layout="fixed"
            alt="LuckyBoo logo"
          />
           <StaticImage
            src="../../images/token-1.png"
            height={50}
            style={{ top: 180, right: 570, transform: 'rotate(-10deg)' }}
            className="howBoo mobile three"
            placeholder="memeraidsFish"
            layout="fixed"
            alt="LuckyBoo logo"
          />
           <StaticImage
            src="../../images/token-1.png"
            height={140}
            style={{ top: 30, right: 220 }}
            className="howBoo"
            placeholder="memeraidsFish"
            layout="fixed"
            alt="LuckyBoo logo"
          />
           <StaticImage
            src="../../images/token-1.png"
            height={110}
            style={{ top: 250, right: 60, transform: 'rotate(10deg)' }}
            className="howBoo mobile four"
            placeholder="memeraidsFish"
            layout="fixed"
            alt="LuckyBoo logo"
          />
    </div>
  </div>
  )
}

export default How
