import React from 'react'
import Slider from 'react-slick'
import { useIntl } from 'gatsby-plugin-intl'
import './story.sass'
import { StaticImage } from 'gatsby-plugin-image'
import { isMobile } from 'react-device-detect'

const settings = {
  dots: true,
  dotsClass: 'slick-dots slick-thumb dots',
  infinite: true,
  speed: 500,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1
}

const Story = () => {
  const intl = useIntl()
  return (
  <div className="storySection">
    { isMobile ? <h1>LuckyBoo Story</h1> : null}
    {
          isMobile
            ? <div className="storyTextBlocksContainer">
                <Slider {...settings}>
                  <p>{intl.formatMessage({ id: 'story-text1' })}</p>
                  <p>{intl.formatMessage({ id: 'story-text2' })}</p>
                  <p>{intl.formatMessage({ id: 'story-text3' })}</p>
                </Slider></div>
            : null
        }
    <div className="storyRow">
        {
          !isMobile
            ? <div className="storyTextBlocksContainer">
              <Slider {...settings}>
                <p>{intl.formatMessage({ id: 'story-text1' })}</p>
                <p>{intl.formatMessage({ id: 'story-text2' })}</p>
                <p>{intl.formatMessage({ id: 'story-text3' })}</p>
                </Slider>
              </div>
            : null
        }
{ isMobile
  ? <StaticImage
            src="../../images/boo-board-dog.png"
            height={220}
            className="storyBoo"
            placeholder="memeraidsFish"
            layout="fixed"
            alt="LuckyBoo logo"
          />
  : <StaticImage
            src="../../images/boo-board-dog.png"
            height={660}
            className="storyBoo"
            placeholder="memeraidsFish"
            layout="constrained"
            alt="LuckyBoo logo"
          /> }
    </div>
  </div>
  )
}

export default Story
