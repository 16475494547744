import React from 'react'
import './roadmap.sass'
import { StaticImage } from 'gatsby-plugin-image'
import { isMobile } from 'react-device-detect'
import { useIntl } from 'gatsby-plugin-intl'

const Roadmap = () => {
  const intl = useIntl()
  return (
  <div className="roadSection">
    <div className="roadRow">
      <div className="roadTextBlocksContainer">
        <h1>{intl.formatMessage({ id: 'walkmap' })} <span>2024</span></h1>
        <p>{intl.formatMessage({ id: 'walkmap-text' })}</p>
      </div>
      {isMobile
        ? <StaticImage
            src="../../images/roadmap-boo.jpg"
            className="roadBoo"
            placeholder="memeraidsFish"
            layout="constrained"
            alt="LuckyBoo logo"
          />
        : <StaticImage
              src="../../images/roadmap-boo.jpg"
              height={750}
              className="roadBoo"
              placeholder="memeraidsFish"
              layout="constrained"
              alt="LuckyBoo logo"
            />}
    </div>
  </div>
  )
}

export default Roadmap
