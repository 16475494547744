import React from 'react'
import './banner.sass'
import { useIntl } from 'gatsby-plugin-intl'
import { isMobile } from 'react-device-detect'

const Banner = () => {
  const intl = useIntl()
  return (
  <div className="bannerSection">
    {isMobile
      ? <div className="bannerTextContainer">
        <h1>{intl.formatMessage({ id: 'banner-title' })}</h1>
        <p>{intl.formatMessage({ id: 'banner-text' })}</p>
      </div>
      : null}
    <div className="bannerRow">
    {!isMobile
      ? <div className="bannerTextContainer">
        <h1>{intl.formatMessage({ id: 'banner-title' })}</h1>
        <p>{intl.formatMessage({ id: 'banner-text' })}</p>
      </div>
      : null }
          <div className="bannerBoo" style={{ top: 210, left: 80 }}>
            <span style={{ background: 'rgb(119, 76, 237, 1)', color: 'rgb(195, 250, 196, 1)' }}>$WIF</span>
            <span>+600%</span>
          </div>
          <div className="bannerBoo" style={{ top: 320, left: 160 }}>
            <span style={{ background: 'rgb(234, 151, 203, 1)', color: 'rgb(119, 76, 237, 1)' }}>$DOGE</span>
            <span>+30%</span>
          </div>
          <div className="bannerBoo mobile" style={{ top: 440, left: 140 }}>
            <span style={{ background: 'rgb(195, 250, 196, 1)', color: '#000' }}>$BOO</span>
            <span>+1000%</span>
          </div>
          <div className="bannerBoo" style={{ top: 140, right: 280 }}>
            <span style={{ background: 'rgba(244, 119, 48, 1)', color: 'rgb(195, 250, 196, 1)' }}>$SOL</span>
            <span>+500%</span>
          </div>
          <div className="bannerBoo" style={{ top: 310, right: 180 }}>
            <span style={{ background: '#370af5', color: 'rgb(234, 151, 203, 1)' }}>$PEPE</span>
            <span>+300%</span>
          </div>
          <div className="bannerBoo" style={{ top: 425, right: 310 }}>
            <span style={{ background: 'rgb(119, 76, 237, 1)', color: 'rgb(195, 250, 196, 1)' }}>$SHIB</span>
            <span>+50%</span>
          </div>
    </div>
  </div>
  )
}

export default Banner
